import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material"
import LoginScreen from "./LoginScreen"
import { Route, Routes, useParams } from "react-router-dom"
import { get, postJson } from "./http"
import React from "react"
import { CardInfo, MemberCardDto, VoucherUsageContextOption, VoucherUse, WalletEntryAndStatus } from "./api"
import MembershipCard from "./MembershipCard"
import SlimHeader from "./SlimHeader"

const isExpired = (card:CardInfo):boolean => {

    const now = new Date()

    const expiration = new Date()
    expiration.setFullYear(card.expYear, card.expMonth - 1, 0)

    return !card.live || (now.getTime() >= expiration.getTime())

}

const LinkedCardView = (props:{card:CardInfo})=>{
    const {card} = props;
    

    return <Card 
        style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "335px",
            height: "185px",
        }}>
        <CardContent>
            {isExpired(card) && <Alert color="error">This card is expired</Alert>}
            <Stack>
                <div style={{textAlign: "right"}}>
                    {card.expMonth}/{card.expYear}
                </div>
                <div style={{textAlign:"center"}}>
                    <Typography variant="h3">{card?.scheme}</Typography>
                </div>
                
            </Stack>
        </CardContent>    
    </Card>
}

const VoucherView = (props: {}) => {

    const { voucherId } = useParams()
    const [isLoading, setLoading] = React.useState(false)
    const [item, setItem] = React.useState<WalletEntryAndStatus>()
    const [memberCard, setMemberCard] = React.useState<MemberCardDto>()
    const [contexts, setContexts] = React.useState<VoucherUsageContextOption[]>()
    const [usage, setUsage] = React.useState<VoucherUse>()
    const [cards, setCards] = React.useState<CardInfo[]>()
    const [contextIdx, setContextIdx] = React.useState<number>()
    const [error, setError] = React.useState<string>()

    const context = (contextIdx !== undefined && contexts) ? contexts[contextIdx]?.context : undefined

    console.log("memberCard is ", memberCard)

    const getContexts = () => {
        get(`/api/vouchers/${voucherId}/use-options`, (status, body) => {
            if (status === 200) {
                const options: VoucherUsageContextOption[] = JSON.parse(body)
                setContexts(options)
                if (options.length === 1) {
                    setContextIdx(0)
                }
            } else {
                setError(body)
            }
        })
    }

    const getUsage = () => {
        get(`/api/vouchers/${voucherId}/usage`, (status, body) => {
            if (status === 200) {
                setUsage(JSON.parse(body))
            }
        })
    }

    const getMemberCard = () => {
        const memberId = item?.member.member_id
        if (memberId) {
            get(`/api/members/${memberId}/membership-card`, (status, body) => {
                if (status === 200) {
                    setMemberCard(JSON.parse(body))
                } else {
                    setError(body)
                }
            })
        }
    }

    const getCards = () => {
        const memberId = item?.member.member_id
        if (memberId) {
            get(`/api/members/${memberId}/cards`, (status, body) => {
                if (status === 200) {
                    setCards(JSON.parse(body))
                } else {
                    setError(body)
                }
            })
        }
    }

    const getVoucher = () => {
        setError(undefined)
        setLoading(true)
        get(`/api/vouchers/${voucherId}`, (status, body) => {
            setLoading(false)
            if (status === 200) {
                setItem(JSON.parse(body))
            } else {
                setError(body)
            }
        })
    }

    React.useEffect(getUsage, [item])
    React.useEffect(getContexts, [])
    React.useEffect(getVoucher, [])
    React.useEffect(getMemberCard, [item])
    React.useEffect(getCards, [item])

    const useItem = () => {
        if (item) {
            setLoading(true)
            postJson(`/api/vouchers/${voucherId}`, JSON.stringify(context), (status, body) => {
                if (status === 200) {
                    getVoucher()
                } else {
                    setError(body)
                    setLoading(false)
                }
            })
        }
    }

    const isAvailable = item?.status === "pending" || item?.status === "synthetic"
    const member = item?.member

    const isBusy = isLoading || (!error && !item && !memberCard) || !contexts
    const disabled = !isAvailable || isLoading

    console.log(
        "status", item?.status, 
        "isBusy", isBusy,
        "isLoading", isLoading, 
        "disabled", disabled,
        "contextIdx", contextIdx,
        "context", context,
        "contexts", contexts)

    return (<>
        <Box style={{ textAlign: "center" }}>
            <Stack spacing={4} style={{ padding: "20px" }}
                alignItems="center"
                justifyContent="center">
                {(isBusy) && <CircularProgress />}
                {item && <>
                    <Stack spacing={3}
                        alignItems="center"
                        justifyContent="center">
                        <Typography gutterBottom variant="h4" component="div">Voucher {item.entry.id}</Typography>

                        {(!isAvailable) && <Alert color="warning" style={{ width: "300px" }}>
                            <Stack spacing={2}>
                                <span>This item has been used</span>
                                {usage && <>
                                    <Stack style={{textAlign:"left"}}>
                                        <span>Details:</span>
                                        <ul>
                                            <li>When: {new Date(usage.whenUsed).toLocaleString()}</li>
                                            {usage.description.length > 0 && <li>Where: {usage.description}</li>}
                                            {usage?.context.csrId && <li>By Whom: {usage.context.csrId}</li>} 
                                        </ul>
                                    </Stack>
                                </>}
                                
                                {/* {usage && <div>Details: {new Date(usage.whenUsed).toLocaleString()} {usage?.context.csrId ? `${usage.context.csrId} @` : ""} {usage.description}</div>} */}
                                
                            </Stack>
                            </Alert>}
                        <Card sx={{ width: 335 }}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={item.entry.image_url}
                                title={item.entry.heading}
                            />
                            <CardContent>
                                <Stack spacing={3}>

                                    <Box>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.entry.heading}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.entry.subheading}
                                        </Typography>
                                    </Box>
                                    {isAvailable && <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Use Location</InputLabel>
                                        <Select
                                            disabled={disabled || contexts?.length === 1}
                                            labelId="demo-simple-select-label"
                                            label="Use Location"
                                            value={contextIdx ?? ''}
                                            onChange={e => setContextIdx(e.target.value as number)}
                                            >
                                            {contexts?.map((option, idx) =>
                                                <MenuItem value={idx}>{option.description}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>}
                                    
                                </Stack>
                            </CardContent>
                            <CardActions>


                                {isAvailable && <Button disabled={disabled || !context} variant="contained" onClick={useItem} >Use Item {item.entry.id}</Button>}
                            </CardActions>
                        </Card>
                        {(member && memberCard) && <MembershipCard
                            member={member}
                            cardInfo={memberCard}
                        />}
                        {!cards && <CircularProgress/>}
                        {(cards && cards.filter(c => !isExpired(c)).length === 0) && <Alert color="warning">This member has no valid linked cards!</Alert>}
                        {cards && cards.map(card => <LinkedCardView card={card}/> )}
                    </Stack>

                </>}
                {error && <Alert color='error'>{error}</Alert>}
            </Stack>
        </Box>
    </>
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: {}) => {
    return (
        <LoginScreen message="Sign-in" loggedInContent={user => <>
            <SlimHeader user={user}>
                <Routes>
                    <Route index element={<Typography>Hello World</Typography>} />
                    <Route path=":voucherId" element={<VoucherView />} />
                </Routes>
            </SlimHeader>
        </>} />
    )
}